<template>
  <!--    Student Form-->
  <div class="studentForm">
    <validation-observer
      ref="studentForm"
      v-slot="{ invalid }"
    >
      <lenon-modal
        :title="`${updateStudentMode ? 'Update' : 'Register'} Student`"
        :show="studentModalOpened"
        :show-overlay="deletingStudent"
        size="md"
        @onClose="closeStudentModal()"
      >
        <error-display :error="error" />
        <b-row>
          <div class="col-md-12" v-if="!updateStudentMode">
            <b-form-checkbox v-model="isOldStudent" switch>
              Old Student
            </b-form-checkbox>
          </div>
          <div class="col-md-12">
            <lenon-select
              v-model="student.class_id"
              name="class_id"
              :options="classes"
              label-name="name"
              value-name="id"
              :placeholder="isOldStudent?'Select Batch':'Select Class'"
              rules="required"
              :label="isOldStudent?'Student Batch':'Student Class'"
            />
          </div>
          <div class="col-md-6">
            <lenon-input
              v-model="student.first_name"
              label="First Name"
              name="first_name"
              placeholder="Eg. Jeffrey"
              rules="required|max_length:50"
              :suffix-icon="null"
              :show-errors="false"
            />
          </div>
          <div class="col-md-6">
            <lenon-input
              v-model="student.middle_name"
              label="Middle Name"
              name="middle_name"
              rules="max_length:50"
              placeholder="Eg. Kofi"
              :suffix-icon="null"
            />
          </div>
          <div class="col-md-6">
            <lenon-input
              v-model="student.last_name"
              label="Last Name"
              name="last_name"
              placeholder="Eg. Adjei"
              rules="required|max_length:50"
              :suffix-icon="null"
              :show-errors="false"
            />
          </div>
          <div class="col-md-6">
            <lenon-select
              v-model="student.gender"
              label="Gender"
              name="gender"
              rules="required"
              label-name="label"
              value-name="value"
              :options="genders"
            />
          </div>
          <div class="col-md-12">
            <lenon-date-picker
              v-model="student.dob"
              label="Date of Birth"
              name="dob"
              placeholder="Student DoB"
              :show-label="true"
              rules="required"
            />
          </div>
          <div class="col-md-12">
            <lenon-input
              v-model="student.address"
              label="Address"
              name="address"
              rules="required"
              placeholder="Eg. Gbawe, Mallam"
            />
          </div>
          <div class="col-md-12 mb-1">
            <lenon-file-button
              label="Upload Photo"
              @onFileUpload="handleFileUpload"
            />
          </div>
          <div
            v-if="studentImage"
            class="col-12 mt-1 mb-2"
          >
            <label>Student Photo</label>
            <div class="d-flex align-items-center">
              <b-avatar
                size="60"
                :src="studentImage"
              />
              <div class="mr-1" />
              <lenon-button
                :icon-only="true"
                icon="XIcon"
                variant="flat-danger"
                @onClick="removeStudentImage()"
              />
            </div>
          </div>
          <div class="col-12">
            <b-form-checkbox
              v-model="showCapture"
              class="mb-1"
            >
              Capture Photo
            </b-form-checkbox>
          </div>
        </b-row>
        <div v-if="showCapture">
          <b-row class="justify-content-center">
            <web-cam
              ref="webCam"
              style="border:5px #fa2 solid;border-radius:5px;background-color: #fa2;"
              :height="350"
              :width="350"
              :select-first-device="true"
              :resolution="{ height: 500, width: 500 }"
              @started="onCameraStart"
              @cameras="setCameras"
            />
            <div
              class="mt-1 flex justify-content-center"
              style="width: 350px;"
            >
              <lenon-select
                :options="cameras"
                label-name="label"
                value-name="deviceId"
                placeholder="Select Camera"
                @input="changeCamera"
              />
            </div>
          </b-row>
          <div class="row justify-content-center ">
            <lenon-button
              :disabled="!streamHasStarted"
              variant="flat-success"
              label="Capture"
              icon="CameraIcon"
              @onClick="onClick()"
            />
            <div class="mr-1" />
            <lenon-button
              :disabled="!streamHasStarted"
              label="Stop"
              variant="flat-danger"
              icon="StopCircleIcon"
              @onClick="stopCamera()"
            />
          </div>
        </div>
        <b-row>
          <div class="col-12 mt-1">
            <b-form-checkbox
              v-model="student.has_parent"
              class="mb-1"
            >
              Add Parents
            </b-form-checkbox>
          </div>
        </b-row>
        <div v-if="student.has_parent">
          <div class="mb-1 text-bold">
            <small>NB: Lenon will notify parents without the mobile app to download it for free. This will help your school interact with them more conveniently.</small>
          </div>
          <div
            v-for="(parent, index) in student.parents"
            :key="index"
            class="row"
          >
            <div class="col-md-12">
              <strong class="text-gray">{{
                parent.relation !== null ? relations[+parent.relation].label : 'Parent' }}</strong>
            </div>
            <div class="col-md-12">
              <div class="row align-items-center">
                <div :class="parent.retrieving || parent.retrieved ? 'col-md-10' : 'col-md-12'">
                  <lenon-input
                    v-model="parent.phone"
                    label="Phone"
                    name="phone"
                    placeholder="Eg. 0242566910"
                    rules="required|phone"
                    :suffix-icon="null"
                    :show-errors="false"
                    @input="(e)=>{retrieveGuardianByPhone(e,index)}"
                  />
                </div>
                <div
                  v-if="parent.retrieving || parent.retrieved"
                  class="col-md-2 mt-md-1"
                >
                  <lenon-button
                    :icon-only="true"
                    loading-text=""
                    :loading="parent.retrieving"
                    icon="CheckCircleIcon"
                    :variant="parent.retrieving ? 'flat-primary' : 'flat-success'"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <lenon-input
                v-model="parent.first_name"
                label="First Name"
                name="parent_first_name"
                placeholder="Eg. Bridget"
                rules="required|max_length:50"
                :suffix-icon="null"
                :show-errors="false"
              />
            </div>
            <div class="col-md-6">
              <lenon-input
                v-model="parent.last_name"
                label="Last Name"
                name="parent_last_name"
                placeholder="Eg. Ampedu"
                rules="required|max_length:50"
                :suffix-icon="null"
                :show-errors="false"
              />
            </div>
            <div class="col-md-12">
              <div class="row align-items-center">
                <div class="col-md-10">
                  <lenon-select
                    v-model="parent.relation"
                    label="Relation"
                    name="relation"
                    rules="required"
                    label-name="label"
                    value-name="value"
                    :options="relations"
                  />
                </div>
                <div class="col-md-2 mt-md-1">
                  <lenon-dropdown icon="TrashIcon">
                    <b-dropdown-item @click="removeParent(index)">
                      Yes
                    </b-dropdown-item>
                    <b-dropdown-divider />
                    <b-dropdown-item>
                      No
                    </b-dropdown-item>
                  </lenon-dropdown>
                </div>
              </div>
            </div>
          </div>
          <div class="float-right mt-md-2">
            <lenon-button
              variant="outline-primary"
              icon="PlusIcon"
              label=""
              tool-tip-text="Add a parent"
              class=""
              @onClick="addParent()"
            />
          </div>
        </div>
        <template slot="modal-actions">
          <b-row student="float-right">
            <lenon-button
              variant="flat-danger"
              icon="XIcon"
              student="mr-1"
              label="Cancel"
              @onClick="closeStudentModal()"
            />
            <div style="margin-right: 10px;" />
            <lenon-button
              :label="updateStudentMode ? 'Update' : 'Create'"
              :disabled="invalid || photoInvalid"
              :loading="studentLoading"
              loading-text="Loading..."
              @onClick="updateStudentMode ? updateStudent() : createStudent()"
            />
          </b-row>
        </template>
      </lenon-modal>
    </validation-observer>
    <lenon-modal
      title="Crop Image"
      :show="showCropper"
      size="md"
      @onClose="onCropperClosed()"
    >
      <cropper
        class="cropper"
        :src="studentImage"
        :stencil-props="{
          aspectRatio: 10 / 12
        }"
        @change="getImage"
      />
      <template slot="modal-actions">
        <b-row student="float-right">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            student="mr-1"
            label="Cancel"
            @onClick="onCropperClosed()"
          />
          <div style="margin-right: 10px;" />
          <lenon-button
            label="Save Image"
            @onClick="saveCroppedImage()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </div>
</template>

<script>

import {
  BRow, BFormCheckbox, BAvatar, BDropdownDivider, BDropdownItem,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import confirm from '@/lenon/mixins/confirm'
import {
  CREATE_STUDENT_M, DELETE_PARENT_STUDENT_M, UPDATE_STUDENT_M,
} from '@/graphql/mutations'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonDatePicker from '@/lenon/components/LenonDatePicker.vue'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import { WebCam } from 'vue-web-cam'
import { Cropper } from 'vue-advanced-cropper'
import urlToFile from '@/lenon/mixins/urlToFile'
import 'vue-advanced-cropper/dist/style.css'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import { RETRIEVE_GUARDIAN_BY_PHONE_Q } from '@/graphql/queries'
import LenonFileButton from '@/lenon/components/LenonFileButton.vue'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'
import parentRelations from '@/lenon/mixins/parentRelations'

export default {
  name: 'StudentRegistration',
  components: {
    ErrorDisplay,
    LenonFileButton,
    LenonDropdown,
    LenonSelect,
    LenonDatePicker,
    LenonInput,
    LenonButton,
    LenonModal,
    ValidationObserver,
    BRow,
    BFormCheckbox,
    WebCam,
    Cropper,
    BAvatar,
    BDropdownItem,
    BDropdownDivider,
  },
  mixins: [showToast, confirm, urlToFile, parentRelations],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: {},
      isOldStudent: false,
      retrieveGuardianTimer: null,
      streamHasStarted: false,
      showCapture: false,
      showCropper: false,
      studentModalOpened: false,
      updateStudentMode: false,
      studentLoading: false,
      deletingStudent: false,
      genders: [{
        label: 'Male',
        value: 0,
      }, {
        label: 'Female',
        value: 1,
      }],
      student: {
        id: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        gender: null,
        class_id: null,
        dob: null,
        interests: null,
        address: null,
        photo: null,
        has_parent: false,
        photo_removed: false,
        parents: [],
      },
      cameras: [],
      batches: [],
      canvas: null,
      studentImage: null,
    }
  },
  computed: {
    photoInvalid() {
      if (!this.showCapture) {
        return false
      }
      return !this.student.photo
    },
    selectedStudent() {
      return this.$store.getters['students/selectedStudent']
    },
    classes() {
      return this.isOldStudent?this.batches:this.$store.getters['termsClasses/termClasses']
    },
  },
  watch: {
    isOldStudent(val){
      if(val){
        this.getBatches()
      }
    },
    modalOpened(opened) {
      this.studentModalOpened = opened
      if (opened && !this.selectedStudent.id) {
        this.resetStudentForm()
      }
    },
    selectedStudent(student) {
      if (student.id) {
        this.resetStudentForm()
        if (student.photo) {
          this.studentImage = `${process.env.VUE_APP_STORAGE_PATH}/${student.photo}`
        }
        this.student = {
          ...student,
          parents: student.parents?.map(p => ({ ...p, retrieved: false, retrieving: false })) || [],
          photo: null,
          has_parent: student?.parents?.length > 0,
          photo_removed: false,
        }
        delete this.student.student_id
        delete this.student.age
        delete this.student.created_at
        delete this.student.status
        this.updateStudentMode = true
      }
    },
  },
  methods: {
    getBatches(){
      if(!this.batches.length){
        this.deletingStudent = true
        this.$http.get("batches").then((res)=>{
          this.batches = res.data
        }).finally(()=>{
          this.deletingStudent = false
        })
      }
    },
    handleFileUpload(upload) {
      this.studentImage = upload.dataUrl
      this.student.photo = upload.file
      this.showCropper = true
    },
    addParent() {
      this.student.parents.push({
        id: null,
        parent_id: null,
        first_name: null,
        last_name: null,
        phone: null,
        relation: null,
        retrieving: false,
        retrieved: false,
      })
    },
    removeParent(i) {
      const parent = this.student.parents[i]
      if (parent?.id) {
        this.$apollo.mutate({
          mutation: DELETE_PARENT_STUDENT_M,
          variables: { id: parent.id },
        })
          .then(res => {
            this.showSuccess('Deleted Successfully')
            this.$store.commit('students/removeStudentParent',
              {
                id: this.student.id,
                parentId: parent.id,
              })
          })
          .catch(err => {
            this.studentLoading = false
            console.log(err)
            this.showError('Something went wrong, please try again')
          })
      } else {
        this.student.parents.splice(i, 1)
      }
    },
    onCameraStart(e) {
      this.streamHasStarted = true
    },
    changeCamera(deviceId) {
      if (deviceId) {
        this.$refs.webCam.changeCamera(deviceId)
      }
    },
    removeStudentImage() {
      this.student.photo = null
      this.student.photo_removed = true
      this.studentImage = null
    },
    stopCamera() {
      this.$refs.webCam.stop()
      this.showCapture = false
    },
    onCropperClosed() {
      this.showCropper = false
    },
    saveCroppedImage() {
      // convert it to a usable data URL
      this.studentImage = this.canvas.toDataURL()
      this.student.photo = this.urlToFile(this.studentImage, 'png')
      // eslint-disable-next-line no-unused-expressions
      this.$refs.webCam?.stop()
      this.showCapture = false
      this.showCropper = false
    },
    getImage(e) {
      this.canvas = e.canvas
    },
    onClick() {
      this.studentImage = this.$refs.webCam.capture()
      this.showCropper = true
    },
    setCameras(cameras) {
      this.cameras = cameras
    },
    resetStudentForm() {
      this.student = {
        id: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        gender: null,
        class_id: this.student.class_id,
        dob: null,
        interests: null,
        address: null,
        photo: null,
        has_parent: false,
        photo_removed: false,
        parents: [
          {
            id: null,
            parent_id: null,
            first_name: null,
            last_name: null,
            phone: null,
            relation: null,
            retrieving: false,
            retrieved: false,
          },
        ],
      }
      this.updateStudentMode = false
      this.studentImage = null
      this.$refs.studentForm.reset()
    },
    closeStudentModal() {
      this.studentModalOpened = false
      this.$emit('modalClosed')
    },
    clearParentForm(i) {
      this.student.parents[i].id = null
      // this.student.parents[i].first_name = null
      // this.student.parents[i].last_name = null
      this.student.parents[i].retrieved = false
      this.student.parents[i].retrieving = false
    },
    retrieveGuardianByPhone(phone, i) {
      if (this.retrieveGuardianTimer) {
        clearTimeout(this.retrieveGuardianTimer)
      }
      if (phone.length < 10) {
        return
      }
      // if (this.updateStudentMode) {
      //   return
      // }
      this.retrieveGuardianTimer = setTimeout(() => {
        this.student.parents[i].retrieving = true
        this.$apollo.query({
          query: RETRIEVE_GUARDIAN_BY_PHONE_Q,
          variables: { phone },
        })
          .then(res => {
            if (res.data.guardian) {
              this.student.parents[i].retrieved = true
              this.student.parents[i].retrieving = false
              this.student.parents[i].first_name = res.data.guardian.first_name || this.student.parents[i].first_name
              this.student.parents[i].last_name = res.data.guardian.last_name || this.student.parents[i].last_name
              this.student.parents[i].parent_id = res.data.guardian.id || this.student.parents[i].parent_id
            } else {
              this.clearParentForm(i)
            }
          })
          .catch(err => {
            this.clearParentForm(i)
          })
      }, 800)
    },
    updateStudent() {
      this.error = {}
      const student = {
        ...this
          .student,
        parents: this.student.has_parent ? this.student.parents.map(p => {
          // eslint-disable-next-line no-param-reassign
          delete p.retrieving
          // eslint-disable-next-line no-param-reassign
          delete p.retrieved
          return p
        }) : [],
      }
      if (!this.student.id) {
        return
      }
      this.studentLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_STUDENT_M,
        variables: {
          input: student,
        },
      })
        .then(res => {
          this.showSuccess('Updated student successfully')
          this.studentLoading = false
          this.$store.commit('students/updateStudent', res.data.updateStudent)
        })
        .catch(err => {
          this.error = err
          this.showError('Failed to update Student')
          this.studentLoading = false
        })
    },
    createStudent() {
      this.error = {}
      const student = {
        ...this
          .student,
        parents: this.student.has_parent ? this.student.parents.map(p => {
          // eslint-disable-next-line no-param-reassign
          delete p.retrieving
          // eslint-disable-next-line no-param-reassign
          delete p.retrieved
          return p
        }) : [],
      }
      this.studentLoading = true
      this.$apollo.mutate({
        mutation: CREATE_STUDENT_M,
        variables: {
          input: student,
        },
        context: {
          hasUpload: true,
        },
      })
        .then(res => {
          this.showSuccess('Created student successfully')
          this.studentLoading = false
          this.$store.commit('students/addStudent', res.data.createStudent)
          this.resetStudentForm()
        })
        .catch(err => {
          this.error = err
          this.showError('Failed to create Student')
          this.studentLoading = false
        })
    },
  },
}
</script>
